import React, { useEffect } from "react";
import * as firebaseui from "firebaseui";

import Auth, { uiConfig } from "./Auth";

import "firebaseui/dist/firebaseui.css";

const FirebaseAuthUI = () => {
  useEffect(() => {
    const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(Auth);
    ui.start("#firebaseui-auth-container", uiConfig);
    return;
  });

  return <div id="firebaseui-auth-container"></div>;
};

export default FirebaseAuthUI;
